<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off">
      <div class="row">
        <div
          class="imgView"
          :style="{ 'background-image': `url(${imageSrc})` }"
          :onerror="`this.src='${defaultImg}'`"
          alt="Image"
          @click="selectImage"
        ></div>
        <!-- @input="pickFile" -->
        <input
          ref="fileInput"
          type="file"
          v-on:change="pickFile"
          class="d-none"
          accept=".jpg, .jpeg, .png"
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="'activityTypeNameAr'"
            :value="activityType.activityTypeNameAr"
            :title="$t('activityTypes.nameAr')"
            :imgName="'activity-types.svg'"
            v-on:changeValue="activityType.activityTypeNameAr = $event"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'activityTypeNameEn'"
            :value="activityType.activityTypeNameEn"
            :title="$t('activityTypes.nameEn')"
            :imgName="'activity-types.svg'"
            v-on:changeValue="activityType.activityTypeNameEn = $event"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'activityTypeDescriptionAr'"
            :value="activityType.activityTypeDescriptionAr"
            v-on:changeValue="activityType.activityTypeDescriptionAr = $event"
            :title="$t('activityTypes.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'activityTypeDescriptionEn'"
            :value="activityType.activityTypeDescriptionEn"
            v-on:changeValue="activityType.activityTypeDescriptionEn = $event"
            :title="$t('activityTypes.descriptionEn')"
            :imgName="'description.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="'activityTypeNotes'"
            :value="activityType.activityTypeNotes"
            v-on:changeValue="activityType.activityTypeNotes = $event"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
          <CustomInput
            :className="'col-md-6'"
            :id="'fullCode'"
            :value="activityType.fullCode"
            v-on:changeValue="activityType.fullCode = $event"
            :title="$t('code')"
            :imgName="'number.svg'"
          />
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateActivityType"
        >
          {{ submitName }}
        </button>
        <router-link :to="{ name: 'ActivityTypes' }" class="btn btn-cancel">
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import DEFAULT_IMG_USER from "@/assets/images/activity-types.svg";
import CustomInput from "@/components/general/CustomInput.vue";
import TextArea from "@/components/general/TextArea.vue";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "ActivityTypeForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    TextArea,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken", "constantsListsData"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      defaultImg: DEFAULT_IMG_USER,
    };
  },
  props: ["activityType", "imageSrc", "submitName"],
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
        this.activityType.mediaFile = file[0];
      } else {
        this.imageSrc = DEFAULT_IMG_USER;
        this.activityType.mediaFile = "";
      }
    },
    async addOrUpdateActivityType() {
      this.$emit("addOrUpdateActivityType");
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss"></style>
